// eslint-disable-next-line
import React from "react";
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";

/** @jsx jsx */
import { jsx } from "@emotion/core";

export function PageTilesBsyc(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));
  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles pageTiles_big",
    item.cssClass || item.anchorName || ""
  );
  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  return (
    <>
      <div className='pageTile tile tile_big'>
        <div className='pageTile__content tile__content'>
          <div
            className='tile__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
          <div className='pageTile__title_bg'>
            <div className='pageTile__title_text'>{page.pageTitle}</div>
          </div>
          <div className='pageTile_details'>
            <div className='pageTile_details_content'>
              <div className='pageTile__title'>{page.pageTitle}</div>
              <div className='pageTile_desc'>
                {page.tileDesc || page.description}
              </div>
              <SiteLink
                className='tile_button'
                to={disableLink ? "" : page.pageUrl}
              >
                READ MORE
              </SiteLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
