import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

const rs = [
  { w: 44, h: 44, barW: 28, barH: 3, space: 8 },
  { w: 82, h: 82, barW: 44, barH: 3, space: 10 },
];

const line = css(
  mq({
    width: [rs[0].barW, null, rs[1].barW],
    height: [rs[0].barH, null, rs[1].barH],
    borderRadius: "4px",
    position: "absolute",
    transitionProperty: "transform",
    backgroundColor: "#fff",
    display: "block",
    transitionDuration: "0.15s",
    transitionDelay: "0.15s",
    transitionTimingFunction: "cubic-bezier(0.55, 0.055, 0.675, 0.19)",
  })
);

export default {
  button: css(
    mq({
      cursor: "pointer",
      zIndex: "1000",
      position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.3s ease-in-out",
      padding: "0",
      //top: [0, null, 12],
      top: 0,
      width: [rs[0].w, null, rs[1].w],
      height: [rs[0].h, null, rs[1].h],
      right: [0, null, 20],
      background: "transparent", //variables.primaryColor,
      border: "none",
      outline: "none",
      font: "inherit",
      color: "inherit",
      textTransform: "none",
      "&:focus": {
        outline: "none",
      },
    })
  ),

  buttonScrolled: css(
    mq({
      top: [0, null, 0],
    })
  ),

  hamburger: css(
    mq({
      width: [rs[0].barW, null, rs[1].barW],
      height: [
        rs[0].barH + rs[0].space * 2,
        null,
        rs[1].barH + rs[1].space * 2,
      ],
      position: "relative",
      display: "block",
    })
  ),

  line1: css(
    mq(line, {
      top: 0,
      transition:
        "top 0.3s 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s 0.1s linear",
    })
  ),
  line2: css(
    mq(line, {
      transition:
        "top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19)",
      top: [rs[0].space, null, rs[1].space],
    })
  ),
  line3: css(
    mq(line, {
      top: [rs[0].space * 2, null, rs[1].space * 2],
    })
  ),

  lineActive1: css({
    opacity: "0",
    top: 18,
    transition:
      "top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.2s linear",
  }),
  lineActive2: css({
    transform: "rotate(-135deg)",
    transition:
      "top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1)",
  }),
  lineActive3: css(
    mq({
      transform: [
        `translate3d(0, -${rs[0].space}px, 0) rotate(-45deg)`,
        null,
        `translate3d(0, -${rs[1].space}px, 0) rotate(-45deg)`,
      ],
      transitionDelay: "0.32s",
      transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    })
  ),
};
